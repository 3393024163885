import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { minHeightCss } from "../SignUpScreen/views/InfoStep/InfoStep"
import MaxWidthContent from "../../ui/components/MaxWidthContent/MaxWidthContent"
import { ROUTES } from "../../navigation/routes"
import theme from "../../ui/theme"
import {
  DESKTOP_BREAKPOINT,
  StyledDesktopOnly,
  StyledMobileOnly,
} from "../../ui/responsive"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../ui/typography/fonts"
import { useHelpUrl } from "../../components/ForgotDetailsModal/ForgotDetailsModal"

const StyledContainer = styled.div`
  ${minHeightCss};
  padding-top: 75px;

  ${DESKTOP_BREAKPOINT} {
    padding-top: 206px;
  }
`

const StyledHeading = styled.h1`
  margin-bottom: 16px;
  font-size: ${theme.fontSizes.large}px;
  line-height: ${theme.lineHeights.mediumPlus}px;
  font-weight: ${theme.fontWeights.bold};
  max-width: 309px;

  ${DESKTOP_BREAKPOINT} {
    ${fancyCondensedFontCss};
    font-size: 44px;
    line-height: 40px;
    font-weight: 400;
    margin-bottom: 23px;
    max-width: none;

    span {
      ${fancyWideFontCss};
      color: ${theme.colors.primary};

      &:first-of-type {
        text-transform: uppercase;
        font-size: 80px;
        line-height: 64px;
      }

      &:last-of-type {
        font-size: 46px;
      }
    }
  }
`

const StyledDescription = styled.p`
  font-size: ${theme.fontSizes.medium}px;
  max-width: 322px;

  ${DESKTOP_BREAKPOINT} {
    font-size: ${theme.fontSizes.smallish}px;
    line-height: 24px;
    max-width: 349px;
  }

  a {
    text-decoration: underline;
  }
`

const NotFoundScreen: React.FC = () => {
  const helpUrl = useHelpUrl()
  return (
    <StyledContainer>
      <MaxWidthContent>
        <StyledMobileOnly>
          <StyledHeading>Whoa. The feed just dropped out.</StyledHeading>
        </StyledMobileOnly>
        <StyledDesktopOnly>
          <StyledHeading>
            <span>Whoa!</span> <br />
            The feed just dropped out<span>.</span>
          </StyledHeading>
        </StyledDesktopOnly>
        <StyledDescription>
          We're not quite sure what just went wrong but you can go to our{" "}
          <a href={helpUrl} target="_blank" rel="noopener noreferrer">
            help page
          </a>{" "}
          or just head <Link to={ROUTES.home.path}>Home</Link>.
        </StyledDescription>
        {/* <StyledDescription> */}
        {/*  We're not quite sure what just went wrong but you can head{" "} */}
        {/*  <Link to={ROUTES.home.path}>home</Link>. */}
        {/* </StyledDescription> */}
      </MaxWidthContent>
    </StyledContainer>
  )
}

export default NotFoundScreen
