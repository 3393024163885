import React from "react"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import NotFoundScreen from "../screens/NotFoundScreen/NotFoundScreen"

const browser = typeof window !== "undefined" && window

const NotFoundPage: React.FC = () => {
  return browser ? (
    <SiteWrapper>
      <SiteLayout>
        <NotFoundScreen />
      </SiteLayout>
    </SiteWrapper>
  ) : null
}

export default NotFoundPage
